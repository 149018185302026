import React from 'react';
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import UserResetPasswordConfirmed from '../components/user/user-reset-password-confirmed';
import locale from '../locale/locale';
import crmClientService from '../services/crmClientService';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

const CustomerResetPage = (props) => {
  const token = !!props.location && !!props.location.state ? props.location.state.token : '';
  const pageData = {
    ...locale.resetPasswordPage.token,
  };
  return (
    <Layout>
      <SEO title="Confirm Password"/>
      <UserResetPasswordConfirmed
        token={token}
        pageData={pageData}
      />
      <GoogleReCaptcha onVerify={crmClientService.v3Callback} />
    </Layout>
  );
};

export default CustomerResetPage;
